import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import "bootstrap/dist/css/bootstrap.min.css";

import { ThemeProvider, createTheme } from '@mui/material/styles';

// route
import { BrowserRouter } from 'react-router-dom';
// redux
import { Provider } from 'react-redux';
// toolkit store
import { store } from './Redux/store'



const theme = createTheme({
  typography: {
    allVariants: {
      fontFamily: 'Font Awesome 6 Brands',
      textTransform: 'none',
      fontSize: 16,
      direction: 'rtl',

    },
  },

  
});



const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <ThemeProvider theme={theme}>

  <React.StrictMode>
    <BrowserRouter>
      <Provider store={store}>
        <App />
      </Provider>
    </BrowserRouter>
  </React.StrictMode>
  </ThemeProvider>

);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
