import React from 'react'
import HeroStyle from './hero.module.css'
import DarkStyle from '../../DarkMode/darkBtn.module.css'
import { useEffect } from 'react';
import { ReactComponent as HomePoster } from '../../../assets/images/home.svg';

function Hero() {
  const servicesSection= () =>{
    window.scrollTo(0,750)
  }
  useEffect(() => {
    const isDarkMode = localStorage.getItem("isDarkMode");
    if (isDarkMode) {
      document.querySelector("body").classList.add(DarkStyle["dark-mode"]);
      document.querySelector("#hero").classList.add(DarkStyle["dark-mode"]);
      document.querySelector("#heroSection")?.classList.add(DarkStyle["heroSection"]);
    }
  }, []);
  return (
    <div id='heroSection'>



  <section className={HeroStyle.hero} id='hero'>


    <div className={"container"}>

      <div className={HeroStyle.context}>
        
        <div className={HeroStyle.area}>
        
        </div>
        <div className={HeroStyle.flexing}>
          <div className={HeroStyle.hero__image}>
            {/* <video src="./assets/videos/first_section.webm" autoplay loop></video> */}
            <a
              href="https://storyset.com/work"
              style={{ visibility: "hidden" }}
            >
              {/* sajkasdjaskldjalksdjalskd */}
            </a>
            <style
              dangerouslySetInnerHTML={{
                __html:
                  "\n              svg#freepik_stories-medical-research:not(.animated) .animable {\n                opacity: 0;\n              }\n\n              svg#freepik_stories-medical-research.animated #freepik--background-simple--inject-2 {\n                animation: 1.5s Infinite linear floating;\n                animation-delay: 0s;\n              }\n\n              svg#freepik_stories-medical-research.animated #freepik--Graphics--inject-2 {\n                animation: 1.5s Infinite linear floating;\n                animation-delay: 0s;\n              }\n\n              svg#freepik_stories-medical-research.animated #freepik--character-3--inject-2 {\n                animation: 1.5s Infinite linear floating;\n                animation-delay: 0s;\n              }\n\n              svg#freepik_stories-medical-research.animated #freepik--character-1--inject-2 {\n                animation: 1.5s Infinite linear floating;\n                animation-delay: 0s;\n              }\n\n              svg#freepik_stories-medical-research.animated #freepik--character-2--inject-2 {\n                animation: 1.5s Infinite linear floating;\n                animation-delay: 0s;\n              }\n\n              @keyframes floating {\n                0% {\n                  opacity: 1;\n                  transform: translateY(0px);\n                }\n\n                50% {\n                  transform: translateY(-10px);\n                }\n\n                100% {\n                  opacity: 1;\n                  transform: translateY(0px);\n                }\n              }\n            "
              }}
            />




<HomePoster/>
          </div>
          <div className={HeroStyle.wrapper}>
            <h1>
              {" "}
              <span style={{ fontSize: 40 }}><i
                      className="fa-solid fa-sm fa-arrow-down fa-bounce"
                      style={{ color: "#eeed3f" }}
                    />{" "}</span>
                    
                    اهلا بك في موقع سوق سينك لخدمات المبيعات والاداره
                    {" "}
            </h1>
            <h2 className={HeroStyle.sentence}>
              <span className={HeroStyle.sliding__text}>
                <h1>انجاز للوقت وتقديم احصائيات بشكل مفصل</h1>
                <div className={HeroStyle.slidingVertical}>
                  <span>ادارة المبيعات واصدار فواتير</span>
                  <span>ادارة المخازن والمتاجر</span>
                  <span>ادارة العيادات والمراكز الطبيه</span>
                  <span>اصدار احصائيات عن كل معاملاتك</span>
                  <span>تحليل البيانات ودراسة السوق </span>
                </div>
                <div className={"text-center"}> 
                  <a onClick={servicesSection} className={`${"btn"} ${HeroStyle.discover_more}`}>
                    {" "}
                    <i
                      className={"fa-solid fa-sm fa-arrow-left fa-fade"}
                      style={{ color: "#eeed3f" }}
                    />{" "}
                    اكتشف المزيد {" "}
                  </a>
                </div>
              </span>
            </h2>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>

  )
}

export default Hero