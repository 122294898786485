import React, { useState } from 'react';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Fade from 'react-reveal/Slide';
import feedBack from './feedback.module.css';

function FAQItem() {
  const [expandedIndex, setExpandedIndex] = useState(null);

  const handleChange = (index) => {
    setExpandedIndex(expandedIndex === index ? null : index);
  };


  const faqs = [
    {
      question: "كيف يمكنني التسجيل في الموقع؟",
      answer: "يمكنك التسجيل في الموقع بالذهاب إلى صفحة التسجيل وملء النموذج المطلوب.",
      examples: ["استخدم اسم مستخدم فريد", "تأكد من إدخال بريد إلكتروني صحيح"],
    },
    {
      question: "كيف يمكنني استعادة كلمة المرور الخاصة بي؟",
      answer: "يمكنك استعادة كلمة المرور الخاصة بك من خلال صفحة نسيت كلمة المرور واتباع الخطوات المطلوبة.",
      examples: ["تحقق من بريدك الإلكتروني لرابط إعادة تعيين كلمة المرور"],
    },
    {
        question: "كيف يمكنني التحقق من حالة طلبي؟",
        answer: "يمكنك التحقق من حالة طلبك عن طريق تسجيل الدخول إلى حسابك والانتقال إلى صفحة حالة الطلب.",
        examples: ["تأكد من أنك قد قدمت رقم الطلب الصحيح", "اطلع على تحديثات حالة الطلب بانتظام"],
      },
      {
        question: "هل يمكنني تغيير معلومات حسابي؟",
        answer: "نعم، يمكنك تغيير معلومات حسابك بالانتقال إلى صفحة إعدادات الحساب وتحديث المعلومات الضرورية.",
        examples: ["تأكد من تأكيد التغييرات بعد التحديث", "احتفظ بمعلومات حسابك آمنة"],
      },
      {
        question: "هل يوجد رقم خدمة العملاء للمساعدة؟",
        answer: "نعم، يمكنك الاتصال بفريق خدمة العملاء للمساعدة عبر الرقم المتاح في صفحة اتصل بنا أو الدعم الفني.",
        examples: ["تأكد من توفر معلومات الحساب عند الاتصال", "استعن بالدعم الفني لحل المشكلات"],
      },


  ];

  return (
      <section className={`${feedBack.faqSection} container2`} style={{ padding: '20px', textAlign: 'right' }}>
        <h1 className={" mb-5"}>الأسئلة الشائعة</h1>
        {faqs.map((faq, index) => (
          <Accordion
            key={index}
            expanded={expandedIndex === index}
            onChange={() => handleChange(index)}
            style={{ margin: '20px 0' }}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <div className={`${feedBack.faqQuestion} ${feedBack.boldText}`}>
                <span>{`السؤال: ${faq.question}`}</span>
              </div>
            </AccordionSummary>
            <AccordionDetails>
              <div className={feedBack.faqAnswer}>
                <p>{`الإجابة: ${faq.answer}`}</p>
                <p>أمثلة:</p>
                <ul>
                  {faq.examples.map((example, i) => (
                    <li key={i}>{example}</li>
                  ))}
                </ul>
              </div>
            </AccordionDetails>
          </Accordion>
        ))}
      </section>
  );
}

export default FAQItem;
