// Feedback.js
import React, { useState } from 'react';
import feedBack from './feedback.module.css';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import DarkStyle from '../DarkMode/darkBtn.module.css';
import CustomTextField from '../shared/CustomTextField';

function Form() {


  const schema = Yup.object().shape({
    name: Yup.string().min(3, "الاسم يجب أن يكون على الأقل 3 أحرف").max(30, "الاسم يجب أن يكون أقل من 30 حرف").required("حقل مطلوب"),
    email: Yup.string().email("البريد الإلكتروني غير صحيح").required("حقل مطلوب").min(10, "البريد الإلكتروني يجب أن يكون على الأقل 10 أحرف ويحتوي على @").max(30, "البريد الإلكتروني يجب أن يكون أقل من 30 حرف"),
    message: Yup.string().max(300, "الرسالة يجب أن لا تتجاوز 300 حرف").min(10, "الرسالة يجب أن تكون على الأقل 10 أحرف"),
    phone: Yup.string().min(10, "رقم الهاتف يجب أن يكون على الأقل 10 أحرف"),
    address: Yup.string(),
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      phone: "",
      address: "",
      message: "",
    },
    validationSchema: schema,
    onSubmit: (values) => {
      // Handle form submission here
      console.log(values);
    },
  });



  
  return (

<section className={`${feedBack.contact__us} ${"col-lg-4 "}`} >
        <div className={"container"}>
          <h1 className={"mt-4 mb-5"}>تواصل معنا</h1>
          <form onSubmit={formik.handleSubmit} name="form">
            <div className={"mb-5 mb-xlg-4"}>
              <CustomTextField
                type="text"
                label="الاسم"
                variant="outlined"
                name="name"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.name}
                error={formik.touched.name && Boolean(formik.errors.name)}
                helperText={formik.touched.name && formik.errors.name}
                placeholder="ادخل الاسم"
                icon="general"
                width="95%"
                height="30px"
              />
            </div>

            <div className={"mb-5 mb-xlg-4"}>
              <CustomTextField
                type="text"
                label="البريد الإلكتروني"
                variant="outlined"
                name="email"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.email}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
                placeholder="ادخل البريد الإلكتروني"
                icon="email"
                width="95%"
                height="30px"
              />
            </div>

            <div className={"mb-5 mb-xlg-4"}>
              <CustomTextField
                type="text"
                label="العنوان"
                variant="outlined"
                name="address"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.address}
                error={formik.touched.address && Boolean(formik.errors.address)}
                helperText={formik.touched.address && formik.errors.address}
                placeholder="ادخل العنوان"
                icon="address"
                width="95%"
                height="30px"
              />
            </div>

            <div className={"mb-5 mb-xlg-4"}>
              <CustomTextField
                type="text"
                label="الهاتف"
                variant="outlined"
                name="phone"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.phone}
                error={formik.touched.phone && Boolean(formik.errors.phone)}
                helperText={formik.touched.phone && formik.errors.phone}
                placeholder="ادخل رقم الهاتف"
                icon="phone"
                width="95%"
                height="30px"
              />
            </div>



            <div className={"mb-5 mb-lg-4"}>


              <textarea
                className={"w-100"}
                rows={4}
                cols={40}
                name="message"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.message}
                placeholder='ادخل رسالتك'
              />
              {formik.touched.message && formik.errors.message && (
                <div className="error-message">{formik.errors.message}</div>
              )}
            </div>

            <div className={'w-100 d-flex justify-content-center mt-5'}>
              <input type="submit" value="إرسال" className={`${feedBack.submit} ${"w-50"}`} />
            </div>
          </form>
        </div>
      </section>




  );
}

export default Form;
