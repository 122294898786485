import React, { useContext } from 'react'
import FooterStyle from './footer.module.css'
import Logo from '../../assets/images/Navbar__Logo.png'
// import { themeContext } from '../ConfigContext/DarkMode'
import { NavLink, Link } from 'react-router-dom'
import DarkStyle from '../DarkMode/darkBtn.module.css'
import { useEffect } from 'react'
import { Facebook, Instagram, Twitter, LinkedIn } from '@mui/icons-material';
import { styled } from '@mui/system';

function Footer() {
  useEffect(() => {
    const isDarkMode = localStorage.getItem("isDarkMode");
    if (isDarkMode) {
      document.querySelector("body").classList.add(DarkStyle["dark-mode"]);
      document.querySelector("footer").classList.add(DarkStyle["dark-mode"]);
      document.querySelector("#social")?.classList.toggle(DarkStyle["social"], isDarkMode);
    }
  }, []);





// Styled components
const SocialIconLink = styled(NavLink)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  marginRight: '10px',
  transition: 'transform 0.3s, color 0.3s',
  color: '#00a02b',

  '&:hover': {
    color: '#fff', // Change to your desired hover color
    transform: 'scale(1.2)',
  },
}));


  return (
    <>
  {/* Start Footer */}
  <footer className={`${"footer-section"} ${FooterStyle.Footer_section}`}>
    <div className={"container"}>



      <div className={`${"pt-5 pb-5"} ${FooterStyle.footer_content}`}>
        <div className={`${"row"} ${FooterStyle.row}`}>
        <div className="col-xl-3">



        <li className={`${FooterStyle.Mail} mt-4`} style={{ listStyleType: 'none', paddingLeft: 0 }}>

<div className={`${FooterStyle.single_cta}`}>
  <div className={`${FooterStyle.cta_text}`}>
    <h4>اتصل بنا</h4>
    <span>+20- 01212117200</span>
  </div>
  <i className={"fas fa fa-phone fa-flip-horizontal"} />
</div>
</li>


    <li className={`${FooterStyle.Mail} mt-4`} style={{ listStyleType: 'none', paddingLeft: 0 }}>

      <div className={`${FooterStyle.single_cta}`}>
        <div className={`${FooterStyle.cta_text}`}>
          <h4>راســـــلـنـــــا</h4>
          <span>info@souqsync.com</span>
        </div>
        <i className={"far fa fa-envelope-open"} />
      </div>
    </li>





    <li className={`${FooterStyle.Mail} mt-4`} style={{ listStyleType: 'none', paddingLeft: 0 }}>

    <div className={`${FooterStyle.single_cta}`} style={{ marginLeft: '10px' }}>
  <div className={`${FooterStyle.cta_text}`}>


    {/* Social Media Icons */}
    <SocialIconLink to="https://www.facebook.com/souqsync" title="Facebook">
      <Facebook style={{ fontSize: '2.3rem' }} />
    </SocialIconLink>
    <SocialIconLink to="https://www.instagram.com/souqsync" title="Instagram">
      <Instagram style={{ fontSize: '2.3rem' }} />
    </SocialIconLink>
    <SocialIconLink to="https://www.twitter.com/souqsync" title="Twitter">
      <Twitter style={{ fontSize: '2.3rem' }} />
    </SocialIconLink>
    <SocialIconLink to="https://www.linkedin.com/souqsync" title="LinkedIn">
      <LinkedIn style={{ fontSize: '2.3rem' }} />
    </SocialIconLink>
  </div>
</div>
</li>




</div>









      <div className={`${"col-xl-2 col-lg-4 col-md-3 col-sm-12"} ${FooterStyle.res}`}>
            <div className={`${FooterStyle.footer_widget}`}>
              <div className={`${ FooterStyle.footer_widget_heading}`}>
                <h3> الروابط</h3>
              </div>
              <ul className={`${"ps-0"} ${FooterStyle.listed}`}>
                <li>
                  <NavLink to="/Home">الرئيسية</NavLink>
                </li>
                <li>
                  <NavLink to="/Nurses">ماذا عنا </NavLink>
                </li>
                <li>
                  <NavLink to="/Devices">الخطط والاسعار</NavLink>
                </li>
                <li>
                  <NavLink to="/Devices"> تواصل معنا</NavLink>
                </li>
              </ul>
            </div>
          </div>






          
          <div className={"col-xl-4 col-lg-6 d-flex justify-content-center align-items-right"}>
  <div className={`${FooterStyle.footer_widget} text-center`}>
    <div className={`${FooterStyle.footer_logo} mb-3 text-left`}>
      <NavLink to="/Home">
        <img src={Logo} alt="Logo" />
      </NavLink>
    </div>
    <div className={`${FooterStyle.footer_text} text-right`}>
      <p>

      سوق سينك هو حل متكامل ومجاني  حيث يجمع بين إدارة المبيعات والحسابات والماليات بفاعلية عالية. يعتمد علي تقنية الحوسبة السحابية، مما يوفر لشركتك قاعدة رقمية متقدمة لتحسين الكفاءة العامة. 




</p>
    </div>
  </div>
</div>




        </div>
      </div>
    </div>
    {/* Copyright */}
    <div className={`${FooterStyle.copyright_area}`}>
      <div className={"container"}>
        <div className={"row"}>
          <div className={"text-center"}>
            <div className={`${FooterStyle.copyright_text}`}>
              <p>
                Copyright © 2024, All Rights Reserved -Eng Ibrahim Hamed 
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    {/* End Copyright */}
  </footer>
  {/* End Footer */}
</>

  )
}

export default Footer