import React from 'react';
import { TypeAnimation } from 'react-type-animation';

const TypingAnimation = () => {
  return (
    <TypeAnimation
    sequence={[

      '      هل تبحث عن نظام إدارة أعمال شامل وسهل الاستخدام يدعم اللغة العربية ويجعل حياتك العملية أكثر كفاءة؟ لدينا الحل المثالي لك! مع نظامنا المتكامل، يمكنك إدارة جميع جوانب عملك بسهولة وفعالية، بغض النظر عن المجال الذي تعمل فيه، سواء كنت في القطاع الطبي، التجارة الإلكترونية، أو أي مجال آخر. نظامنا هو مساعدك الشخصي الذي يبقيك على اطلاع دائم بكل شيء.',
      5000, 
   
    ]}
    wrapper="span"
    speed={70}
    style={{ fontSize: '1em', display: 'inline-block' }}
    repeat={Infinity}
  />
  );
};

export default TypingAnimation;
