import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import ourPrograms from './ourPrograms.module.css';
import textData from './textData.json';
import DarkStyle from '../../DarkMode/darkBtn.module.css';
import one from "../../../assets/images/aboutImgs/edit.png";
import two from "../../../assets/images/aboutImgs/medical-team.png";
import three from "../../../assets/images/aboutImgs/market.png";
import four from "../../../assets/images/aboutImgs/onlinestore.png";
import five from "../../../assets/images/aboutImgs/lawyer.png";

function OurPrograms() {

  document.title = "مجالات العمل";

  useEffect(() => {
    const isDarkMode = localStorage.getItem("isDarkMode");
    if (isDarkMode) {
      document.querySelector("#AboutWork")?.classList.toggle(DarkStyle["AboutWork"], isDarkMode);
      document.querySelector("#AboutWorkCardOne")?.classList.toggle(DarkStyle["AboutWorkCardOne"], isDarkMode);
      document.querySelector("#AboutWorkCardTwo")?.classList.toggle(DarkStyle["AboutWorkCardOne"], isDarkMode);
      document.querySelector("#AboutWorkCardThree")?.classList.toggle(DarkStyle["AboutWorkCardOne"], isDarkMode);
      document.querySelector("#AboutWorkCardThree")?.classList.toggle(DarkStyle["AboutWorkCardOne"], isDarkMode);
      document.querySelector("#AboutWorkCardFour")?.classList.toggle(DarkStyle["AboutWorkCardOne"], isDarkMode);
      document.querySelector("#AboutWorkCardFive")?.classList.toggle(DarkStyle["AboutWorkCardOne"], isDarkMode);
    }
  }, []);

  const medicalFeatures = [
    'إدارة فعّالة للطاقم الطبي والموظفين.',
    'متابعة خاصة للحالات الحرجة وعمليات الجراحة.',
    'تطبيق آلي لمزايا التأمين وفقًا للشرائح التأمينية.',
    'فوترة إلكترونية وإتمام المهام المحاسبية.',
    'جرد المخزون وتتبَّع كمياته دوريًا.',
    'تقارير مفصلة لتقييم الأوضاع وتحسين الأداء.',
  ];

  const marketFeatures = [
    'إدارة فعّالة للمحلات والمخازن بكفاءة عالية.',
    'تتبع حركة البضائع والمخزون بدقة وفعالية.',
    'إصدار فواتير البيع والشراء بواسطة نظام إلكتروني مبتكر.',
    'تنظيم العروض الترويجية وإدارة العروض الخاصة بالعملاء.',
    'تحليلات متقدمة لأداء المبيعات ومتابعة التوزيع الجغرافي.',
    'توفير منصة للدفع الإلكتروني والتعامل بأمان مع المدفوعات.',
  ];

  const onlinStore = [
    'أدر حسابات متجرك و تابع المبيعات واحسب الأرباح باحترافية.',
    'حصل فواتيرك عبر الإنترنت عن طريق بوابات الدفع الإلكتروني الآمنة.',
    'نظِّم عملاءك وأنشئ سجلات لكل منهم لمتابعتهم بفعالية.',
    'تنظيم العروض الترويجية وإدارة العروض الخاصة بالعملاء.',
    'تابع حركة المنتجات في مخزونك واحصل على تنبيهات.',
    'توفير منصة للدفع الإلكتروني والتعامل بأمان مع المدفوعات.',
  ];
  const lawyers = [
    'إضافة الموكليين وحفظ المعاملات.',
    'إدارة القضايا ومتابعة حالاتها.',
    'تسجيل مواعيد زيارات الموكلين والجلسات وإرسالها عبر SMS.',
    'حفظ الملفات والمرفقات الخاصة بالموكلين والقضايا.',
    'إصدار الفواتير الإلكترونية عن أتعاب المحاماة.',
    'إنشاء تقارير شاملة للقضايا المسجلة والمبالغ المستحقة على الموكلين.',
  ];
  
  const {
    headings: { howToBenefit },
    paragraphs: { howToBenefitDescription },
    cards: { cardOneTitle, cardOneContent, cardTwoTitle, cardTwoContent, cardThreeTitle, cardThreeContent ,cardFourTitle,cardFourContent,cardFive,cardFiveContent},
    buttons: { registerHere, bookMedical, bookDevice }
  } = textData;

  return (
    <div id="AboutWork" style={{ paddingBottom: '70px' }} dir="rtl">
      <section className={`${ourPrograms.work_section} ${ourPrograms.padding_top}`}>
        <div className="container">


          
          <div className={ourPrograms.heading_container}>
            <h2>{howToBenefit}</h2>
            <p style={{ lineHeight: '25px' }} className="w-75 pb-3">{howToBenefitDescription}</p>
          </div>

          


    
          <div className={ourPrograms.detail_container}>
            <div
              style={{ pointerEvents: 'none' }}
            id="AboutWorkCardOne" className={`${ourPrograms.box} ${ourPrograms.b_1} ${ourPrograms.fullWidth}`}>
              <div className={ourPrograms.top_box}>
                <div className={ourPrograms.icon_box}>
                  <img src={one} alt="" />
                </div>
                <h5 style={{ fontWeight: 'bold' }}>{cardOneTitle}</h5>
              </div>
              <div className={ourPrograms.bottom_box}>
                <p>{cardOneContent}</p>
                
              </div>
            </div>






            <div id="AboutWorkCardTwo" className={`${ourPrograms.box} ${ourPrograms.b_1} ${ourPrograms.halfWidth}`}>
              <div className={ourPrograms.top_box}>
                <div className={ourPrograms.icon_box}>
                  <img src={two} alt="" />
                </div>
                <h5 style={{ fontWeight: 'bold' }}>{cardTwoTitle}</h5>
              </div>
              <div className={ourPrograms.bottom_box}>
                <p style={{ minHeight: '5em' }}>{cardTwoContent}</p>
                <hr style={{ margin: '10px 0' }} />
                <ul className={ourPrograms.features_list}>
                  {medicalFeatures.map((feature, index) => (
                    <li key={index} style={{ fontWeight: 'bold' }}>
                      <span className={ourPrograms.dot}></span>
                      <span>{feature}</span>
                    </li>
                  ))}
                </ul>
                <div className={ourPrograms.btn_box}>
                  <Link to="/login" className={ourPrograms.btn_1}>{bookMedical}</Link>
                </div>
              </div>
            </div>

            <div id="AboutWorkCardThree" className={`${ourPrograms.box} ${ourPrograms.b_1} ${ourPrograms.halfWidth}`}>
              <div className={ourPrograms.top_box}>
                <div className={ourPrograms.icon_box}>
                  <img src={three} alt="" />
                </div>
                <h5 style={{ fontWeight: 'bold' }}>{cardThreeTitle}</h5>
              </div>
              <div className={ourPrograms.bottom_box}>
                <p style={{ minHeight: '8em' }}>{cardThreeContent}</p>
                <hr style={{ margin: '10px 0' }} />
                <ul className={ourPrograms.features_list}>
                  {marketFeatures.map((feature, index) => (
                    <li key={index} style={{ fontWeight: 'bold' }}>
                      <span className={ourPrograms.dot}></span>
                      <span>{feature}</span>
                    </li>
                  ))}
                </ul>
                <div className={ourPrograms.btn_box}>
                  <Link to="/login" className={ourPrograms.btn_1}>{bookMedical}</Link>
                </div>
              </div>
            </div>

            <div id="AboutWorkCardFour" className={`${ourPrograms.box} ${ourPrograms.b_1} ${ourPrograms.halfWidth}`}>
              <div className={ourPrograms.top_box}>
                <div className={ourPrograms.icon_box}>
                  <img src={four} alt="" />
                </div>
                <h5 style={{ fontWeight: 'bold' }}>{cardFourTitle}</h5>
              </div>
              <div className={ourPrograms.bottom_box}>
                <p style={{ minHeight: '8em' }}>{cardFourContent}</p>
                <hr style={{ margin: '10px 0' }} />
                <ul className={ourPrograms.features_list}>
                  {onlinStore.map((feature, index) => (
                    <li key={index} style={{ fontWeight: 'bold' }}>
                      <span className={ourPrograms.dot}></span>
                      <span>{feature}</span>
                    </li>
                  ))}
                </ul>
                <div className={ourPrograms.btn_box}>
                  <Link to="/login" className={ourPrograms.btn_1}>{bookMedical}</Link>
                </div>
              </div>
            </div>

            <div id="AboutWorkCardFive" className={`${ourPrograms.box} ${ourPrograms.b_1} ${ourPrograms.halfWidth}`}>
              <div className={ourPrograms.top_box}>
                <div className={ourPrograms.icon_box}>
                  <img src={five} alt="" />
                </div>
                <h5 style={{ fontWeight: 'bold' }}>{cardFive}</h5>
              </div>
              <div className={ourPrograms.bottom_box}>
<p style={{ minHeight: '8em' }}>{cardFiveContent}</p>
                <hr style={{ margin: '10px 0' }} />
                <ul className={ourPrograms.features_list}>
                  {lawyers.map((feature, index) => (
                    <li key={index} style={{ fontWeight: 'bold' }}>
                      <span className={ourPrograms.dot}></span>
                      <span>{feature}</span>
                    </li>
                  ))}
                </ul>
                <div className={ourPrograms.btn_box}>
                  <Link to="/login" className={ourPrograms.btn_1}>{bookMedical}</Link>
                </div>
              </div>
            </div>

          </div>
        </div>
      </section>
    </div>
  );
}

export default OurPrograms;
