import React, { useEffect, useState } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';
import LoadingSpinner from './Components/Spinner/Spinner';
import Navbar from './Components/Nabar/NavbarComponent';
import DarkMode from './Components/DarkMode/DarkBTN';
import Scroll from './Components/ScrollToTop/Scroll';
import withLoading from './wrappedComponent';
import Footer from './Components/footer/Footer';
import Home from './Pages/Home/Home';
import Login from './Pages/Login/Login';



import Contactus from './Pages/ContactUs/ContactUs';
import Notfound from './Components/NotFoundComponent/NotFound';
import Signup from './Pages/SignUp/Signup';
import About from './Pages/AboutPage/About';




function App() {
  const [isLoading, setLoading] = useState(true);
  const [showRoutes, setShowRoutes] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setShowRoutes(true);
      setLoading(false);
    }, 1500);
  }, []);

  const location = useLocation();
  const page = location.pathname.split('/')[1];
  const isLoginPage = page === 'login' || page === 'signup';

  return (
    <div className="App">
      {isLoading ? (
        <LoadingSpinner />
        
      ) : (
        
        <>
        <Navbar />

          <DarkMode />
          <Scroll />
          <AnimatePresence mode="wait">

   


            {showRoutes && (
              <Routes location={location} key={location.pathname}>
                {isLoginPage && (
                  <>
                    <Route path="login" element={<Login />} />
                    <Route path="signup" element={<Signup />} />


                  </>
                )}
                {!isLoginPage && (
                  <>
                    <Route index path="/home" element={<Home />} />
                    <Route path="/" element={<Home />} />
                    <Route path="*" element={<Home />} />
                    <Route path="contactus" element={<Contactus />} />
                    <Route path="programs" element={<About />} />
                    <Route path="notfound" element={<Notfound />} />



                  </>
                )}
              </Routes>
            )}

{!isLoginPage && <Footer />}
          </AnimatePresence>
        
        </>
      )}
    </div>
  );
}

export default App;
