import React from 'react'
import herostyle from '../Hero/herostyle.module.css'
import hero from '../../../assets/images/aboutImgs/Website Creator-bro (1).svg'
import { NavLink } from 'react-router-dom'
import DarkStyle from '../../DarkMode/darkBtn.module.css'
import { useEffect } from 'react'

import TypingAnimation from './typingAnimation/typingAnimation'; // Import the TypingAnimation component

function Hero() {
  useEffect(()=>{
    // Dark Mode
    const isDarkMode = localStorage.getItem("isDarkMode")
    if(isDarkMode){
      document.querySelector("#AboutHero")?.classList.toggle(DarkStyle["AboutHero"], isDarkMode);
    }
  },[])
  return (
    <>
    <body dir="rtl">

    <div id='AboutHero' className={herostyle.hero_area}>  
       
        <section className={herostyle.hero_section}>
         
                <div className={"container"}>
                  <div className={`${herostyle.row}  ${'row'}`}>
                    <div className={"col-md-7"}>
                      <div className={herostyle.detail_box}>
                        <h1>
                          
                        نظام  متكامل لإدارة كافة أعمالك
                        
                                                </h1>
                      

                      <TypingAnimation/>
                        <div className={`${herostyle.btn_box} ${"text-center"}`}>
                          <NavLink to="/contactus" className={herostyle.btn_1} >
                                   تواصل معنا                          
                          </NavLink>
                        </div>
                      </div>
                    </div>


                    <div className={"col-md-5"}>
                      <div className={herostyle.img_box}>
                        <img src={hero} alt=""/>
                      </div>
                    </div>
                  </div>
                </div>
            </section>
              </div>

              </body>
              </>
  )
}

export default Hero