import { useContext, useEffect, useRef, useState } from "react";
import NavStyle from "./Navbarcomponent.module.css";
import Logo from "../../assets/images/Navbar__Logo.png";
import { NavLink, Link, useHistory } from "react-router-dom";
import 'bootstrap/dist/js/bootstrap.bundle.min';

function Navbar() {
  const [username, setUsername] = useState("");
  const [isLoggedOut, setIsLoggedOut] = useState(false);
  const [userRole, setUserRole] = useState("");
  const buttonRef = useRef(null);

  function handleLogout() {
    localStorage.removeItem("user");
    localStorage.removeItem("isDarkMode");
    setIsLoggedOut(true);
  }



  
  const handleNavLinkClick = () => {
    // Trigger the click event of the button
    if (buttonRef.current) {
      buttonRef.current.click();
    }
  };



  return (
    <>
      {/* Start Navbar */}
      <nav className={`navbar navbar-expand-lg pb-0`}>
      <div className={`container`} style={{ marginTop: '2%' }}>
          {/* Toggle Button */}
          <button
            className={`navbar-toggler ${NavStyle.navbar_toggler}`}
            type="button"
            data-bs-toggle="offcanvas"
            data-bs-target="#offcanvasNavbar"
            aria-controls="offcanvasNavbar"
            aria-label="Toggle navigation"
          >
            <span
              className={`navbar-toggler-icon ${NavStyle.navbar_toggler_icon}`}
            />
          </button>

          <div className={`row flex-row-reverse ${NavStyle.row}`}>


            <div className={`col-xxl-2 ps-xxl-0 col-xl-3 col-lg-3 col-md-6 col-sm-6`}>
              <div className={`d-flex align-items-center me-auto`}>
                <figure className={NavStyle.Navbar__Logo}>
                  <NavLink to="/Home" className={`navbar-brand`}>
                    <img src={Logo} alt="Logo" />
                  </NavLink>
                </figure>
              </div>
            </div>
            <div
              className={`${NavStyle.toggler} col-xxl-8 me-xxl-5 col-xl-9 mt-xxl-1 mt-xl-2 mt-lg-1 col-lg-9 col-md-1 col-sm-3 d-flex justify-content-between`}
            >
              <div
                className={`${NavStyle.offcanvas} offcanvas offcanvas-end`}
                tabIndex={-1}
                id="offcanvasNavbar"
                aria-labelledby="offcanvasNavbarLabel"
              >

                            {/* Logo */}

                <div className={`offcanvas-header`}>
                  <figure
                    className={`${NavStyle.Navbar_Logo} ${NavStyle.Navbar__Logo_offcanvas} text-center w-100 mt-3`}
                  >
                    <NavLink className={`navbar-brand`} to="/Home">
                      <img src={Logo} alt="Logo" />
                    </NavLink>
                  </figure>
                  <button
                    type="button"
                    className={`btn-close border-white btn-close-white`}
                    data-bs-dismiss="offcanvas"
                    aria-label="Close"
                  />
                </div>
                <div className={`offcanvas-body ${NavStyle.offcanvas_body}`}>
                  {/* Buttons */}
                  <ul className={`offset-xxl-0 offset-xl-0 ms-xl-0 col-xxl-4 col-xl-4 col-lg-4 ms-lg-2 ps-lg-0 navbar-nav mt-xxl-0 ${NavStyle.FlexDir}`} style={{ display: 'flex', alignItems: 'center' }}>
                    <li className={`nav-item ms-auto w-100`}>
                      {username && <></>}
                      {!username ? (
                        <>




<button
        ref={buttonRef}
        type="button"
        className={`btn ${NavStyle.btn_outline_secondary}`}
        data-bs-dismiss="offcanvas"
        style={{ display: 'none' }}
      >
        Close Offcanvas
      </button>


                        
      <div style={{ display: 'flex', alignItems: 'center' ,justifyContent:'center'}}>
    <div style={{ marginRight: '15px' }}>
      <NavLink
        to="/signup"
        type="button"
        className={`btn ${NavStyle.btn_outline_primary}`}
        onClick={handleNavLinkClick}
        style={{ display: 'inline-block' }}


      >
        انضم الآن
      </NavLink>
    </div>
    <div>
      <NavLink
        to="/login"
        type="button"
        className={`btn ${NavStyle.btn_outline_secondary}`}
        onClick={handleNavLinkClick}
        style={{ display: 'inline-block' }}

      >
        الدخول
      </NavLink>
    </div>
  </div>


                        </>
                      ) : (
                        <>
                          {/* Login Logout */}
                          <a className={NavStyle.ProfilePic}>
                            <div className={`${NavStyle.ProfilePic} dropdown`}>
                              <button
                                className={`btn dropdown-toggle ${NavStyle.ProfilePicButton} ${NavStyle.btn_Profile_Outlined}`}
                                type="button"
                                id="profileDropdownMenuButton"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                              >
                                <span> {username || "User"} </span>
                                <i className="fa fa-fw fa-user text-light fa-xl"></i>
                              </button>
                              <ul
                                className={`dropdown-menu`}
                                aria-labelledby="profileDropdownMenuButton"
                              >
                                {userRole === "user" ? (
                                  <li>
                                    <NavLink
                                      to="/userProfile"
                                      className={`dropdown-item text-end ${NavStyle.dropdown_item}`}
                                      onClick={handleNavLinkClick}

                                    >
                                      الصفحة الشخصية
                                    </NavLink>
                                  </li>
                                ) : (
                                  <li>
                                    <NavLink
                                      to="/adminpage"
                                      className={`dropdown-item text-end ${NavStyle.dropdown_item}`}
                                      onClick={handleNavLinkClick}

                                    >
                                      الصفحة الشخصية
                                    </NavLink>
                                  </li>
                                )}
                                <li>
                                  <NavLink
                                    to="/Login"
                                    className={`dropdown-item text-end text-danger ${NavStyle.dropdown_item}`}
                                    onClick={handleLogout}
                                  >
                                    <i className="bx bx-log-out me-1"></i> الخروج
                                  </NavLink>
                                </li>
                              </ul>
                            </div>
                          </a>
                        </>
                      )}
                    </li>
                  </ul>
                  {/* Buttons */}

                  <ul className={`navbar-nav col-xxl-9 offset-xxl-0 ms-xl-5 offset-xl-0  mt-xxl-0 col-xl-7 col-lg-8 ${NavStyle.navbar_nav}`}>
                    
                    <li className={`nav-item cont ${NavStyle.cont}`}>
                    <NavLink
  to="/contactus"
  className={`nav-link ${NavStyle.nav_link}`}
  onClick={handleNavLinkClick}
  style={{ fontWeight: 900 }}
>
  تواصل معنا
</NavLink>

                    </li>
                    <li className={`nav-item`}>
                      <NavLink to="/plans" className={`nav-link ${NavStyle.nav_link}`}
                                              onClick={handleNavLinkClick}
                                              style={{ fontWeight: 900 }}

                      >
                        الخطط والاسعار
                      </NavLink>
                    </li>
                    <li className={`nav-item`}>
                      <NavLink className={`nav-link ${NavStyle.nav_link}`} to="/programs"
                                              onClick={handleNavLinkClick}
                                              style={{ fontWeight: 900 }}

                      >
مجالات العمل
                      </NavLink>
                    </li>
                    <li className={`nav-item`}>
                      <NavLink
                        to="/home"
                        className={`nav-link ${NavStyle.nav_link}`}
                        aria-current="page"

                        onClick={handleNavLinkClick}
                        style={{ fontWeight: 900 }}

                      >
                        الرئيسية
                      </NavLink>
                    </li>
                
                
                  </ul>
               
               
               
               
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>
      {/* End Navbar */}
    </>
  );
}

export default Navbar;