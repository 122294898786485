// LoadingSpinner.js

import React, { useEffect } from "react";
import "./spinner.css";
import logoImage from "./logo.png"; // Replace with the path to your logo image

export default function LoadingSpinner() {
  return (
    <div className="spinner-container">
  <div class="loader__element"></div>
      <div className="spinner-content">
        <img src={logoImage} alt="Your Logo" className="logo" />
      </div>
      <div class="loader__element"></div>
    </div>
  );
}
