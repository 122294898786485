// Feedback.js
import React from 'react';
import feedBack from './feedback.module.css';
import { motion } from 'framer-motion';
import Form from './Form';
import FAQItem from './FAQItem';

function Feedback() {
  return (
    <motion.div
      id='contact'
      className={`${"d-flex flex-wrap justify-content-center mx-4"} ${feedBack.ContactPage}`}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      variants={{ duration: 0.2 }}
      transition={{ yoyo: Infinity }}
      style={{ overflow: 'hidden', marginTop: '50px', marginBottom: '50px' }}
    >
      <div className={`d-flex flex-wrap ${feedBack.formFaqContainer}`}>
        <Form />
        <FAQItem />
      </div>
    </motion.div>
  );
}

export default Feedback;
