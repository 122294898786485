import React, { useState, useEffect } from "react";
import { FaMoon, FaSun } from "react-icons/fa";
import ScrollStyle from "./darkBtn.module.css";

const DarkMode = () => {
  const [isDarkMode, setIsDarkMode] = useState(false);

  const handleToggle = () => {
    const newDarkMode = !isDarkMode;
    setIsDarkMode(newDarkMode);
    if (newDarkMode) {
        localStorage.setItem("isDarkMode", JSON.stringify(newDarkMode));
      } else {
        localStorage.removeItem("isDarkMode");
      }
};

  useEffect(() => {
    const storedDarkMode = JSON.parse(localStorage.getItem("isDarkMode"));
    if (storedDarkMode !== null) {
      setIsDarkMode(storedDarkMode);
    }
  }, []);

  useEffect(() => {
 
    document.body.classList.toggle(ScrollStyle["dark-mode"], isDarkMode);
    document.querySelector("nav")?.classList.toggle(ScrollStyle["dark-mode"], isDarkMode);
    // document.querySelector("section")?.classList.toggle(ScrollStyle["dark-mode"], isDarkMode);
    document.querySelector("footer")?.classList.toggle(ScrollStyle["dark-mode"], isDarkMode);
    document.querySelector("#heroSection")?.classList.toggle(ScrollStyle["heroSection"], isDarkMode);
    document.querySelector("#social")?.classList.toggle(ScrollStyle["social"], isDarkMode);
    document.querySelector("#offcanvasNavbar")?.classList.toggle(ScrollStyle["offcanvasNavbar"], isDarkMode);
    document.querySelector("#Services")?.classList.toggle(ScrollStyle["Services"], isDarkMode);
    document.querySelector("#EveryWhere")?.classList.toggle(ScrollStyle["everyWhere_EveryWhere"], isDarkMode);
    document.querySelector("#TopRated")?.classList.toggle(ScrollStyle["TopRated"], isDarkMode);
    document.querySelector("#contact")?.classList.toggle(ScrollStyle["contact"], isDarkMode);
    document.querySelector("#post")?.classList.toggle(ScrollStyle["post"], isDarkMode);
    document.querySelector("#main")?.classList.toggle(ScrollStyle["main"], isDarkMode);
    document.querySelector("#NurseSidebar")?.classList.toggle(ScrollStyle["NurseSidebar"], isDarkMode);
    


    // About
    document.querySelector("#AboutHero")?.classList.toggle(ScrollStyle["AboutHero"], isDarkMode);
    document.querySelector("#AboutWork")?.classList.toggle(ScrollStyle["AboutWork"], isDarkMode);
    document.querySelector("#AboutWorkCardOne")?.classList.toggle(ScrollStyle["AboutWorkCardOne"], isDarkMode);
    document.querySelector("#AboutWorkCardTwo")?.classList.toggle(ScrollStyle["AboutWorkCardOne"], isDarkMode);
    document.querySelector("#AboutWorkCardThree")?.classList.toggle(ScrollStyle["AboutWorkCardOne"], isDarkMode);
    document.querySelector("#AboutWorkCardFour")?.classList.toggle(ScrollStyle["AboutWorkCardOne"], isDarkMode);

    document.querySelector("#AboutWorkCardFive")?.classList.toggle(ScrollStyle["AboutWorkCardOne"], isDarkMode);
    document.querySelector("#AboutOurServices")?.classList.toggle(ScrollStyle["AboutOurServices"], isDarkMode);
    document.querySelector("#AboutOurServicesCard")?.classList.toggle(ScrollStyle["AboutOurServicesCard"], isDarkMode);
    document.querySelector("#AboutOurServicesCardTwo")?.classList.toggle(ScrollStyle["AboutOurServicesCard"], isDarkMode);
    document.querySelector("#AboutOurServicesCardThree")?.classList.toggle(ScrollStyle["AboutOurServicesCard"], isDarkMode);
    document.querySelector("#AboutOurServicesCardFour")?.classList.toggle(ScrollStyle["AboutOurServicesCard"], isDarkMode);
    document.querySelector("#AboutOurServicesCardFive")?.classList.toggle(ScrollStyle["AboutOurServicesCard"], isDarkMode);
    // About
    
    // messanger page
    document.querySelector("#time")?.classList.toggle(ScrollStyle["time"], isDarkMode);
    document.querySelector("#userName")?.classList.toggle(ScrollStyle["userName"], isDarkMode);
    document.querySelector("#usernameDiv")?.classList.toggle(ScrollStyle["usernameDiv"], isDarkMode);
    document.querySelector("#headerText")?.classList.toggle(ScrollStyle["headerText"], isDarkMode);
    document.querySelector("#compoConversation")?.classList.toggle(ScrollStyle["compoConversation"], isDarkMode);
   

 

}, [isDarkMode]);


  return (
    <div
      className={`${ScrollStyle.top_to_btm} ${isDarkMode ? ScrollStyle.dark : ""}`}
      onClick={handleToggle}
    >
      <div className={ScrollStyle.icon_wrapper}>
        <FaSun
          className={`${ScrollStyle.icon_position} ${
            ScrollStyle.icon_style
          } ${isDarkMode ? ScrollStyle.fade_out : ScrollStyle.fade_in}`}
        />
        <FaMoon
          className={`${ScrollStyle.icon_position} ${
            ScrollStyle.icon_style
          } ${isDarkMode ? ScrollStyle.fade_in : ScrollStyle.fade_out}`}
        />
      </div>
    </div>
  );
};

export default DarkMode;
