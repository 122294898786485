
import * as Yup from 'yup';

export const validationSchemas = [
  Yup.object().shape({
    userName: Yup.string().required('اسمك مطلوب'),
    userEmail: Yup.string()
      .email('بريد إلكتروني غير صالح')
      .required('البريد الإلكتروني مطلوب'),
    password: Yup.string().required('كلمة المرور مطلوبة'),
  }),
  Yup.object().shape({
    country: Yup.string().required('الدولة مطلوبة'),
    userPhone: Yup.string()
      .required('رقم الموبايل مطلوب')
      .matches(/^\+?\d+$/, 'يجب أن يحتوي رقم الهاتف على أرقام فقط ويمكن أن يتضمن رمز الزائد (+)')
      .min(10, 'يجب أن يكون رقم الهاتف على الأقل 10 أرقام')
      .max(15, 'يجب أن يكون رقم الهاتف على الأكثر 15 رقمًا')
      .required('رقم الهاتف مطلوب'),
  }),
  Yup.object().shape({
    userType: Yup.string().required('نوع المستخدم مطلوب'),
  }),
];
