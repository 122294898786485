// CustomTextField.js
import React from 'react';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import { Email, Lock, Home, Phone, Person } from '@mui/icons-material';

const CustomTextField = ({
  type,
  label,
  variant,
  name,
  onChange,
  onBlur,
  value,
  error,
  helperText,
  placeholder,
  icon,
  width,
  height,
}) => {
  const getAdornmentIcon = () => {
    switch (icon) {
      case 'email':
        return <Email />;
      case 'password':
        return <Lock />;
      case 'address':
        return <Home />;
      case 'phone':
        return <Phone />;
      case 'general':
        return <Person />;
      default:
        return null;
    }
  };

  return (
    <TextField
      type={type}
      label={label}
      variant={variant}
      name={name}
      onChange={onChange}
      onBlur={onBlur}
      value={value}
      error={error}
      helperText={helperText}
      placeholder={placeholder}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            {getAdornmentIcon()}
          </InputAdornment>
        ),
      }}
      sx={{
        fontFamily: 'Droid Arabic Naskh, sans-serif',
        marginBottom: '15px',
        width: width || '100%',
        height: height || '50px', 
        "& label": {
          transformOrigin: "top right",
          right: 28,
          left: "auto",
        },
        "& legend": {
          textAlign: "right",
        },
        "& .MuiFormHelperText-root": {
          fontSize: 'small',
        },
      }}
      dir="rtl"
    />
  );
};

export default CustomTextField;
