import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Autocomplete from "@mui/material/Autocomplete";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Fade from "react-reveal/Slide";
import LoginStyle from "../../Components/Login/logintry.module.css";
import { styled } from "@mui/system";
import { NavLink } from "react-router-dom";

// Toastify
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import staticData from "./staticData.json";

// Import validation schemas
import { validationSchemas } from "./validationSchemas";



const Signup = () => {
  document.title = "تسجيل حساب";

  const ButtonRow = styled("div")({
    display: "flex",
    justifyContent: "space-between",
    marginTop: "20px",
  });

  const GreenButton = styled(Button)({
    width: "48%",
    backgroundColor: "#4CAF50",
    color: "white",
    "&:hover": {
      backgroundColor: "#45a049",
    },
  });

  const inputSx = {
    fontFamily: "Droid Arabic Naskh, sans-serif",
    marginBottom: "15px",
    "& label": {
      transformOrigin: "top right",
      right: 28,
      left: "auto",
    },
    "& legend": {
      textAlign: "right",
    },
    "& .MuiFormHelperText-root": {
      fontSize: "small",
    },
  };


  const notify = () => {
    toast.error("التسجيل مغلق حاليا", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
      style: { textAlign: "center" },
    });
  };

  const [activeStep, setActiveStep] = useState(0);


  const formik = useFormik({
    initialValues: {
      userName: "",
      userEmail: "",
      userPhone: "",
      password: "",
      country: "",
      email: "",
      phone: "",
      address: "",
      userType: "",
      joinReason: "",
      workType: "",
      workPlace: "",
    },
    validationSchema: validationSchemas[activeStep],
    onSubmit: (values) => {
      notify();
    },
  });


  const isNextButtonActive = () => {
    return formik.dirty && formik.isValid;
  };

  const handleNext = () => {
    if (activeStep === 2 || formik.isValid) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    } else {
      formik.setTouched((touched) => {
        const newTouched = { ...touched };
        Object.keys(formik.values).forEach((field) => {
          newTouched[field] = true;
        });
        return newTouched;
      });

      if (activeStep === 0) {
        setActiveStep(1);
      } else if (activeStep === 1) {
        setActiveStep(2);
      }
    }
  };

  
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    formik.setTouched({});
  };

  useEffect(() => {
    if (activeStep === 1) {
      formik.setTouched({});
    }
  }, [activeStep, formik.setTouched]);





  return (
    <>
      <main className={LoginStyle["main_css"]}>
        <ToastContainer
          position="top-center"
          autoClose={5000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
          className={LoginStyle.Toastify}
        />

        <Fade left distance="10%" duration={500}>
          <div className={LoginStyle["container_form"]}>
            <div className={LoginStyle["steper"]}>
              <Stepper activeStep={activeStep} alternativeLabel>
                <Step>
                  <StepLabel>بيانات الدخول</StepLabel>
                </Step>
                <Step>
                  <StepLabel>تفاصيل العنوان</StepLabel>
                </Step>
                <Step>
                  <StepLabel>معلومات اضافيه </StepLabel>
                </Step>
              </Stepper>
            </div>
            <form
              className={LoginStyle["form_css"]}
              onSubmit={formik.handleSubmit}
            >




              {activeStep === 0 && (
                <>
                  <TextField
                    label="الاسم كامل"
                    variant="outlined"
                    name="userName"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.userName}
                    error={
                      formik.touched.userName && Boolean(formik.errors.userName)
                    }
                    helperText={
                      formik.touched.userName && formik.errors.userName
                    }
                    fullWidth
                    sx={{ ...inputSx }}
                    dir="rtl"
                  />

                  <TextField
                    label="البريد الالكتروني"
                    variant="outlined"
                    name="userEmail"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.userEmail}
                    error={
                      formik.touched.userEmail &&
                      Boolean(formik.errors.userEmail)
                    }
                    helperText={
                      formik.touched.userEmail && formik.errors.userEmail
                    }
                    fullWidth
                    sx={{ ...inputSx }}
                    dir="rtl"
                  />

                  <TextField
                    label="كلمة المرور"
                    variant="outlined"
                    type="password"
                    name="password"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.password}
                    error={
                      formik.touched.password && Boolean(formik.errors.password)
                    }
                    helperText={
                      formik.touched.password && formik.errors.password
                    }
                    fullWidth
                    sx={{ ...inputSx }}
                    dir="rtl"
                  />
                </>




              )}
              {activeStep === 1 && (
                <>
                  <Autocomplete
                    freeSolo
                    id="free-solo-2-demo"
                    disableClearable
                    fullWidth
                    options={staticData.countries}
                    getOptionLabel={(option) => option.label}
                    onChange={(e, value) => {
                      formik.setFieldValue("country", value.value);
                      formik.setFieldTouched("country", true);
                    }}
                    onBlur={formik.handleBlur}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="الدولة"
                        variant="outlined"
                        name="country"
                        onChange={formik.handleChange}
                        value={formik.values.country}
                        error={
                          formik.touched.country &&
                          Boolean(formik.errors.country)
                        }
                        helperText={
                          formik.touched.country && formik.errors.country
                        }
                        fullWidth
                        sx={{ ...inputSx }}
                      />
                    )}
                  />

                  <Autocomplete
                    freeSolo
                    id="free-solo-2-demo"
                    disableClearable
                    fullWidth
                    options={staticData.governorates}
                    getOptionLabel={(option) => option.label}
                    onChange={(e, value) => {
                      formik.setFieldValue("Governorate", value.value);
                      formik.setFieldTouched("Governorate", true);
                    }}
                    onBlur={formik.handleBlur}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="المحافظه"
                        variant="outlined"
                        name="Governorate"
                        onChange={formik.handleChange}
                        value={formik.values.Governorate}
                        error={
                          formik.touched.Governorate &&
                          Boolean(formik.errors.Governorate)
                        }
                        helperText={
                          formik.touched.Governorate &&
                          formik.errors.Governorate
                        }
                        fullWidth
                        sx={{ ...inputSx }}

                      />
                    )}
                  />

                  <TextField
                    label=" رقم الموبايل"
                    variant="outlined"
                    name="userPhone"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.userPhone}
                    error={
                      formik.touched.userPhone &&
                      Boolean(formik.errors.userPhone)
                    }
                    helperText={
                      formik.touched.userPhone && formik.errors.userPhone
                    }
                    fullWidth
                    sx={{ ...inputSx }}
                    dir="rtl"
                  />
                </>
              )}

              {activeStep === 2 && (
                <>
                  <Autocomplete
                    freeSolo
                    id="free-solo-2-demo"
                    disableClearable
                    fullWidth
                    options={staticData.userTypes}
                    getOptionLabel={(option) => option.label}
                    onChange={(e, value) => {
                      formik.setFieldValue("userType", value.value);
                      formik.setFieldTouched("userType", true);
                    }}
                    onBlur={formik.handleBlur}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="حدد نوع المستخدم للانضمام"
                        variant="outlined"
                        name="userType"
                        onChange={formik.handleChange}
                        value={formik.values.userType}
                        error={
                          formik.touched.userType &&
                          Boolean(formik.errors.userType)
                        }
                        helperText={
                          formik.touched.userType && formik.errors.userType
                        }
                        fullWidth
                        sx={{
                        ...inputSx
                        }}
                      />
                    )}
                  />
                  {formik.values.userType !== "user" && (
                    <>
                      <TextField
                        label="طبيعة العمل"
                        variant="outlined"
                        name="workType"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.workType}
                        error={
                          formik.touched.workType &&
                          Boolean(formik.errors.workType)
                        }
                        helperText={
                          formik.touched.workType && formik.errors.workType
                        }
                        fullWidth
                        InputProps={{
                          placeholder: "وصف لطبيعة العمل الذي تقدمه",
                        }}
                        sx={{ ...inputSx }}
                        dir="rtl"
                      />
                      <TextField
                        label="العنوان تفصيلي"
                        variant="outlined"
                        name="workPlace"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.workPlace}
                        error={
                          formik.touched.workPlace &&
                          Boolean(formik.errors.workPlace)
                        }
                        helperText={
                          formik.touched.workPlace && formik.errors.workPlace
                        }
                        fullWidth
                        InputProps={{
                          placeholder: "المنطقه التي تعمل فيها ",
                        }}
                        sx={{ ...inputSx }}
                        dir="rtl"
                      />
                    </>
                  )}
                  {formik.values.userType === "user" && (
                    <TextField
                      label=" الغرض من الانضمام"
                      variant="outlined"
                      name="joinReason"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.joinReason}
                      error={
                        formik.touched.joinReason &&
                        Boolean(formik.errors.joinReason)
                      }
                      helperText={
                        formik.touched.joinReason && formik.errors.joinReason
                      }
                      fullWidth
                      InputLabelProps={{
                        shrink: true,
                      }}
                      InputProps={{
                        placeholder: " (اختياري) الغرض من الانضمام",
                      }}
                      sx={{ ...inputSx }}
                      dir="rtl"
                    />
                  )}
                </>
              )}






              <ButtonRow>
                {activeStep > 0 || activeStep === 0 ? (
                  <GreenButton
                    variant="contained"
                    onClick={handleBack}
                    disabled={activeStep === 0}
                  >
                    السابق
                  </GreenButton>
                ) : null}
                {activeStep < 2 && (
                  <GreenButton
                    type="button"
                    variant="contained"
                    color="primary"
                    onClick={handleNext}
                    disabled={!isNextButtonActive()}
                  >
                    التالي
                  </GreenButton>
                )}
                {activeStep === 2 && (
                  <GreenButton
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={!formik.isValid || !formik.dirty}
                  >
                    إرسال
                  </GreenButton>
                )}
              </ButtonRow>

              <div style={{ marginTop: "20px" }}>
                <NavLink
                  to="/login"
                  style={{
                    fontSize: "15px",
                    fontWeight: "bold",
                    color: "#00a02b",
                  }}
                  className="pe-1"
                >
                  دخول
                </NavLink>
                <span className="ms-auto"> لديك حساب؟ </span>
              </div>
            </form>
          </div>
        </Fade>
      </main>
    </>
  );
};

export default Signup;
