import React, { useState } from "react";
import LoginStyle from '../../Components/Login/logintry.module.css';
import { NavLink } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from 'yup';
import axios from 'axios';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Fade from 'react-reveal/Slide';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function Login() {
  const [showForgotPassword, setShowForgotPassword] = useState(false);
  document.title = "تسجيل الدخول";

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
      rememberMe: false,
    },
    validationSchema: Yup.object().shape({
      email: Yup.string().email('بريد إلكتروني غير صالح').required("البريد الإلكتروني مطلوب"),
      password: Yup.string().required("كلمة السر مطلوبة")
    }),



    onSubmit: (values) => {
      // axios.post('', values)
      //   .then((res) => {
      //     if (res.data.success === true) {
      //       localStorage.setItem('token', res.data.token)
      //       localStorage.setItem('user', JSON.stringify(res.data.data))
      //       navigate('/Home')
      //     } else {
      //       const notify = () =>
      //         toast.error(res.data.message, {
      //           position: "top-center",
      //           autoClose: 5000,
      //           hideProgressBar: true,
      //           closeOnClick: true,
      //           pauseOnHover: true,
      //           draggable: true,
      //           progress: undefined,
      //           theme: "colored",
      //           style: { textAlign: "center" },
      //         });
      //       notify();
      //     }
      //   });

      const notify = () =>
        toast.error('التسجيل مغلق حاليا', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
          style: { textAlign: "center" },
        });
      notify();
    }
  });

  
  const forgotPasswordFormik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: Yup.object().shape({
      email: Yup.string().email('بريد إلكتروني غير صالح').required("البريد الإلكتروني مطلوب"),
    }),
    onSubmit: (values) => {
      // Handle forgot password logic
      const notify = () =>
        toast.success('تم إرسال رابط إعادة تعيين كلمة المرور', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
          style: { textAlign: "center" },
        });
      notify();
    }
  });

  const inputSx = {
    fontFamily: "Droid Arabic Naskh, sans-serif",
    marginBottom: "15px",
    "& label": {
      transformOrigin: "top right",
      right: 28,
      left: "auto",
    },
    "& legend": {
      textAlign: "right",
    },
    "& .MuiFormHelperText-root": {
      fontSize: "small",
    },
  };

  return (
    <>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        className={LoginStyle.Toastify}
      />

      <main className={LoginStyle["main_css"]}>
        <Fade left distance="10%" duration={500}>
          <div className={LoginStyle["container_form"]}>
            {!showForgotPassword ? (
              <form className={LoginStyle["form_css"]} onSubmit={formik.handleSubmit}>
                <h3 style={{ textAlign: 'center', fontFamily: 'Font Awesome 6 Brands', marginBottom: '20px', marginTop: '20px' }}>
                  تسجيل الدخول
                </h3>

                <TextField
                  type="text"
                  label="البريد الالكترونى"
                  variant="outlined"
                  name="email"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.email}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  helperText={formik.touched.email && formik.errors.email}
                  placeholder="ادخل البريد الالكتروني"
                  sx={{
                    ...inputSx,
                  }}
                  dir="rtl"
                />

                <TextField
                  type="password"
                  label="كلمة المرور"
                  variant="outlined"
                  name="password"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.password}
                  error={formik.touched.password && Boolean(formik.errors.password)}
                  helperText={formik.touched.password && formik.errors.password}
                  placeholder="ادخل كلمة المرور"
                  sx={{
                    ...inputSx,
 
                  }}
                  dir="rtl"
                />

                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '15px' }}>
                  <FormControlLabel
                    control={<Checkbox color="primary" onChange={formik.handleChange} name="rememberMe" />}
                    label="تذكرني"
                  />
                </div>

                <NavLink to="#" onClick={() => setShowForgotPassword(true)} style={{ fontSize: '15px', fontWeight: 'bold', color: '#00a02b', marginBottom: '20px' }} className="pe-1">
                  نسيت كلمة المرور
                </NavLink>

                <Button type="submit" variant="contained" style={{ backgroundColor: '#00a02b', color: '#fff' }} fullWidth>
                  تسجيل الدخول
                </Button>

                <div style={{ marginTop: '20px' }}>
                  <NavLink to="/signup" style={{ fontSize: '15px', fontWeight: 'bold', color: '#00a02b' }} className="pe-1">
                    انضم لنا الأن
                  </NavLink>
                  <span className="ms-auto"> ليس لديك حساب؟ {" "}</span>
                </div>
              </form>
            ) : (
              <form className={LoginStyle["form_css"]} onSubmit={forgotPasswordFormik.handleSubmit}>
                <h3 style={{ textAlign: 'center', fontFamily: 'Font Awesome 6 Brands', marginBottom: '20px', marginTop: '-25%' }}>
                  استعادة كلمة المرور
                </h3>

                <TextField
                  type="text"
                  label="البريد الالكترونى"
                  variant="outlined"
                  name="email"
                  onChange={forgotPasswordFormik.handleChange}
                  onBlur={forgotPasswordFormik.handleBlur}
                  value={forgotPasswordFormik.values.email}
                  error={forgotPasswordFormik.touched.email && Boolean(forgotPasswordFormik.errors.email)}
                  helperText={forgotPasswordFormik.touched.email && forgotPasswordFormik.errors.email}
                  placeholder="ادخل البريد الالكتروني"
                  sx={{
                    ...inputSx,
                  
                  }}
                  dir="rtl"
                />

                <Button type="submit" variant="contained" style={{ backgroundColor: '#00a02b', color: '#fff', marginTop: '20px' }} fullWidth>
                  إرسال رابط إعادة تعيين كلمة المرور
                </Button>

                <Button
                  onClick={() => setShowForgotPassword(false)}
                  variant="outlined"
                  style={{ borderColor: '#00a02b', color: '#00a02b', marginTop: '10px' }}
                  fullWidth
                >
                  العودة إلى تسجيل الدخول
                </Button>
              </form>
            )}
          </div>
        </Fade>
      </main>
    </>
  );
}
