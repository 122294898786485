import React, { useEffect } from 'react'
import SerVStyle from './services.module.css'
// SVGs
import Cashir from '../../../assets/images/cashir.png'
import FastTime from '../../../assets/images/fast.png'
import Product from '../../../assets/images/products.png'
import accounting from '../../../assets/images/accounting.png'
import warehouse from '../../../assets/images/warehouse.png'
import status from '../../../assets/images/status.png'
import DarkStyle from '../../DarkMode/darkBtn.module.css'

function Services() {
  useEffect(() => {
    const isDarkMode = localStorage.getItem("isDarkMode");
    if (isDarkMode) {
      document.querySelector("#Services")?.classList.toggle(DarkStyle["Services"], isDarkMode);
    }
  }, []);
  return (
    <section className={SerVStyle.ourServices} id='Services'>
  <div className={`${"row"} ${SerVStyle.row}`}>
    <h2 className={SerVStyle.section_heading}>يمكن ادارة مشروعك من خلال</h2>
  </div>
  <div className={`${"row"} ${SerVStyle.row}`}>
    <div className={SerVStyle.column}>
      <div className={SerVStyle.card}>
        <div className={SerVStyle.icon_wrapper}>
          {/* <i className={"fas fa-hammer"}/> */}
          <img src={Cashir}/>
        </div>
        <h3 className='pt-2'>تجربة شاشة البيع (الكاشير) المتميزة</h3>
<p>
في عالم الأعمال، نحن نؤمن بأن توفير وخدمات عالية الجودة لا يجب أن يكون مرتبطًا بتكاليف باهظة. لهذا السبب، نقدم لك تجربة استثنائية عبر شاشة البيع (الكاشير)، حيث يمكنك اصدار فواتير خاصه بالمبيعات وتفاصيله بضغطه واحده .</p>

      </div>
    </div>



    <div className={SerVStyle.column}>
  <div className={SerVStyle.card}>
    <div className={SerVStyle.icon_wrapper}>
      {/* <i className={"fas fa-brush"}/> */}
      <img src={Product}/>
    </div>
    <h3 className='pt-2'>تخصيص وتنظيم منتجاتك بسهولة</h3>
    <p>
    استفد من إمكانيات الإضافة والتصنيف السهلة لتجعل تنظيم منتجاتك أمرًا يسيرًا. يقدم لك جدول العمل المرن الذي نقدمه مرونة في تحديد أقسام المنتجات وتصنيفها. سواء كنت بحاجة إلى تقسيم السلع أو تحديد فئات محددة.    </p>
  </div>
</div>



    <div className={SerVStyle.column}>
      <div className={SerVStyle.card}>
        <div className={SerVStyle.icon_wrapper}>
          {/* <i className={"fas fa-wrench"}/> */}
          <img src={FastTime}/>
        </div>
        <h3 className='pt-2'> خدمة سريعة </h3>
        <p>
        في شركتنا، ندرك قيمة الوقت وأهمية توفير خدمة سريعة وفعالة. ولهذا السبب، نفخر بتقديم خدمتنا الممتازة والسريعة لتلبية احتياجاتك بسرعة وكفاءة.
        </p>
      </div>
    </div>
    <div className={SerVStyle.column}>
      <div className={SerVStyle.card}>
        <div className={SerVStyle.icon_wrapper}>
          {/* <i className={"fas fa-truck-pickup"}/> */}
          <img src={warehouse}/>
        </div>
        <h3 className='pt-2'>تتبع المخازن والمستودعات </h3>
        <p>
        نقدم حلاً متكاملًا لتتبع المخازن والمستودعات ضمن نظام إدارة الموارد المؤسسية باستخدام تقنيات حديثة، يمكنك تحسين عمليات التخزين وضمان استفادة قصوى من المخزون. دمجنا بين الكفاءة والدقة لضمان نجاح إدارة مخزونك وتحقيق أهداف عملك.
    </p>
      </div>
    </div>
    <div className={SerVStyle.column}>
      <div className={SerVStyle.card}>
        <div className={SerVStyle.icon_wrapper}>
          {/* <i className={"fas fa-broom"}/> */}
          <img src={status}/>

        </div>
        <h3 className='pt-2'>اصدار تقارير واحصائيات زمنيه </h3>
        <p>
        من خلال نظامنا، يمكنك إصدار تقارير دقيقة وإحصائيات زمنية فعالة، مما يساعدك في فهم تطورات عملك واتخاذ قرارات مستنيرة. سواء كنت بحاجة إلى تحليل الأداء الشهري أو إعداد تقارير فورية، نحن هنا لدعمك في كل خطوة على الطريق.
        </p>
      </div>
    </div>
    <div className={SerVStyle.column}>
      <div className={SerVStyle.card}>
        <div className={SerVStyle.icon_wrapper}>
          {/* <i className={"fas fa-plug"}/> */}
          <img src={accounting}/>
        </div>
        <h3 className='pt-2'>تسجيل حساباتك بسهوله</h3>
        <p>
        في عالم الأعمال، يصبح إدارة حسابات الدائن والمدين والموردين أمرًا حيويًا لنجاح أي منظمة. نقدم لك أداة تسجيل حساباتك بسهولة وفعالية، مما يتيح لك السيطرة الكاملة على التدفقات المالية. سواء كنت تحتاج إلى تتبع المدفوعات أو استلام المدفوعات ومراقبة تفاصيلها.
        </p>
      </div>
    </div>
  </div>
</section>
  )
}

export default Services